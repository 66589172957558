@font-face {
  font-family: 'GenosGFG';
  font-weight: normal;
  font-style: normal;
  src: local("GenosGFG"), url("./fonts/GenosGFG-Regular.woff") format("woff"), local("GenosGFG"), url("./fonts/GenosGFG-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'GenosGFG';
  font-weight: normal;
  font-style: italic;
  src: local("GenosGFG"), url("./fonts/GenosGFG-RegularItalic.woff") format("woff"), local("GenosGFG"), url("./fonts/GenosGFG-RegularItalic.woff2") format("woff2");
}

@font-face {
  font-family: 'GenosGFG';
  font-weight: bold;
  font-style: normal;
  src: local("GenosGFG"), url("./fonts/GenosGFG-Bold.woff") format("woff"), local("GenosGFG"), url("./fonts/GenosGFG-Bold.woff2") format("woff2");
}



// Override default variables before the import
$header-bg: #0E2C64;
$body-bg: #ccc;
$body-main-bg: #F4F6FC;
$bg-blue: #E3EAED;
$primary: #ec6608;
$secondray: #005ca9;
$headings-color: #0066b3;
$headings-font-weight: bold;
$headings-font-family: "GenosGFG",
Arial,
serif;
$font-family-base: "GenosGFG",
Arial,
serif;

.bg-main {
  background-color: $body-main-bg;
  position: relative;
  min-height: min(50vh, 130vw);

  @media (max-width: 992px) {
    h1 {
      font-size: 30px;
      font-weight: bold;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-family: 'GenosGFG', Arial, serif;
    }
  }
}
.pt-20 {
	padding-top: 200px;
}
.bg-header {
	background-color: $header-bg;
}

.angled-header {
  position: relative !important;
  overflow: hidden;
}

.typoclaim {
	font-size: 75px;
	font-weight: 900;
	font-family: 'GenosGFG', Arial, serif;
	color: #fff;
	line-height: 1.0;

	@media (max-width: 992px) {
		font-size: 48px;
	}
}

.angled-header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -200px;
  left: 0;
  width: 100%;
  height: 200px;
  background: #F4F6FC;
  transform: skewY(-5deg);
  transform-origin: bottom left;
}

.keyvisual {
  &_wrapper {
    height: 500px;
    width: 100%;
    height: 500px;
    background-color: $header-bg;

    .bvb-claim {
      width: 650px;
      height: auto;
      position: absolute;
      top: calc(50% - 60px);
      left: 50px;
      display: block;
      z-index: 999;

      @media screen and (max-width: 767px) {
        width: 80%;
        left: 10%;
      }
    }

    .bvb-logo {
      width: 150px;
      height: auto;
      position: absolute;
      top: 15px;
      right: 50px;
      display: block;
			@media screen and (max-width: 992px) {
				right: 30px;
			}
    }
  }



  position: absolute;
  right: 0;
  bottom: 0;
  align-self: flex-end;
  display: inline-block;
  max-width: 80%;
  width: 100%;
  height: auto;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.o-footer {
  &>nav {
    background-color: #3e3f40;
  }
}

.m-banner {
  &__inner {
    @extend .bg-primary,
    .pt-3,
    .pb-1,
    .pt-lg-3,
    .pt-lg-3,
    .pb-lg-1,
    .pr-lg-5;

    @media (max-width: 992px) {
      padding-left: 1rem;

      h1 {
        font-size: 20px;
        color: #ffffff;
        font-weight: bold;
        font-family: 'GenosGFG', Arial, serif;
      }
    }

    padding-left: 5rem;

    @media (min-width: 992px) {
      top: 280px;
      position: absolute;
      z-index: 5;

      h1 {
        font-weight: bold;
        font-size: 40px;
        color: #ffffff;
        font-family: 'GenosGFG', Arial, serif;
      }
    }
  }
}

.m-banner-navbar {
  @extend .navbar-brand,
  .ml-auto,
  .h-100,
  .align-self-end;

  &__inner {
    @extend .bg-primary,
    .pt-3,
    .pb-1,
    .pl-5;

    padding-right: 10rem;
    margin-right: -30px;
    top: 0;
    position: inherit;
    z-index: inherit;

    h1 {
      font-size: 40px;
      color: #ffffff;
      font-weight: bold;
      font-family: 'GenosGFG', Arial, serif;
    }
  }
}

.logo {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.embed-responsive-1by2:before {
  padding-top: 150%;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }
